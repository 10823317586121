const videoModule = () => import(/* webpackChunkName: 'components/video' */'../../components/video/video');
const allMediasViewerGenericModule = () => import(/* webpackChunkName: 'components/allMediasViewerGeneric' */'../../components/allMediasViewerGeneric/allMediasViewerGeneric');
const seeMoreModule = () => import(/* webpackChunkName: 'components/seeMore' */'../../components/seeMore/seeMore');
const crossSellModule = () => import(/* webpackChunkName: 'components/crossSell' */'../../components/crossSell/crossSell');
const biasingSearchViewModule = () => import(/* webpackChunkName: 'components/biasingSearchParameters' */'../../components/biasingSearchParameters/biasingSearchParameters');
const stickyElementModule = () => import(/* webpackChunkName: 'utils/stickyElement' */'../../utils/js/stickyElement');
const basketMulticottageViewModule = () => import(/* webpackChunkName: 'components/basketMulticottageView' */'../../components/basketMulticottage/basketMulticottage');
const msgRenovationViewModule = () => import(/* webpackChunkName: 'components/msgRenovationView' */'../../components/msgRenovation/msgRenovation');
const postParametersViewModule = () => import(/* webpackChunkName: 'components/postParametersView' */'../../components/postParameters/postParameters');
const startingPriceModule = () => import(/* webpackChunkName: 'components/startingPrice' */'../../components/startingPrice/startingPrice');
const bookingFormModule = () => import(/* webpackChunkName: 'components/bookingForm' */'../../components/bookingForm/bookingForm');

import { getSeason } from '../../utils/js/getSeason';
import { getVideoThumbnail } from '../../utils/js/getVideoThumbnail';

const isSunParks = GLOBAL_SCRIPTS.UNIVERS.toUpperCase() === 'SP';

const ProductDefault = Backbone.View.extend({
  initialize(options = {}) {
    const $body = $('body');

    seeMoreModule().then(({ seeMore }) => {
      window.seeMore = seeMore;
      seeMore.init();
    });

    if (GLOBAL_SCRIPTS.IS_CSE) {
      this.setParamsCSE();

      if (GLOBAL_SCRIPTS.IS_CGOS || GLOBAL_SCRIPTS.IS_CE_NO_RESA) {
        $('body').addClass('noCottagesTab');
      }
    }

    // Initialise basket
    if (sessionStorage.getItem('basketDatas') !== null && PRODUCT_SCRIPTS.PRODUCT_TAB !== `tab_${GLOBAL_SCRIPTS.UNIVERS}_cottages` && PRODUCT_SCRIPTS.PRODUCT_TAB !== `tab_${GLOBAL_SCRIPTS.UNIVERS}_dayguest`) {
      basketMulticottageViewModule().then(({ BasketMulticottageView }) => {
        this.basketMulticottageView = new BasketMulticottageView(JSON.parse(sessionStorage.getItem('basketDatas')));
      });
    }

    // Update productHistory Cookie when visiting a FP
    if (!$body.hasClass('js-accommodationDetails')) {
      this.setProductHistoryCookie();
      dispatcher.listenTo(dispatcher, 'product:setProductHistoryCookie', this.setProductHistoryCookie);
    }

    this.isStickyNav = typeof options.isStickyNav !== 'undefined' ? options.isStickyNav : true;

    if (this.isStickyNav) {
      this.initStickyNav();
      resize.addToFunctionList(this.initStickyNav, true, this);
    }

    if (($body.hasClass('domain') || GLOBAL_SCRIPTS.IS_CGOS || GLOBAL_SCRIPTS.IS_CE_NO_RESA) && DEVICE.isMobile) {
      stickyElementModule().then(({ stickyElement }) => {
        stickyElement.init({
          selector: 'body',
          fixedStartSelector: GLOBAL_SCRIPTS.IS_CGOS || GLOBAL_SCRIPTS.IS_CE_NO_RESA ? '' : '.searchForm-mobileValidation',
          classSticky: 'stickyCTASearch',
          deviceList: 'mobile',
          fixedStart: GLOBAL_SCRIPTS.IS_CGOS || GLOBAL_SCRIPTS.IS_CE_NO_RESA ? 0 : () => {
            return $('.searchForm-mobileValidation').offset().top;
          },
          nameSpace: 'stickyCTASearchMobile'
        });
      });
    }

    videoModule().then(({video}) => {
      new video();
    });

    if (typeof GLOBAL_SCRIPTS.POST_PARAMS_INITIATED === 'undefined') {
      // On instancie la vue qui permet l'envoi des params en post qu'une seule fois
      postParametersViewModule().then(({PostParametersView}) => {
        new PostParametersView();
      });
    }

    if (!DEVICE.isDesktop) {
      const $productNav = $('.submenu-navigation');
      const activeTab = $productNav.data('activetab');
      const $activeTab = $('#' + activeTab);

      if ($activeTab.length) {
        $productNav.animate({ scrollLeft: $activeTab.position().left }, 500);
      }
    }

    this.initScrollAfterLoad();
    this.initScrollToTop();

    // update biasing search
    if ($('.js-accommodations').length === 0 && $('.js-accommodationDetails').length === 0 && $('.js-activity').length === 0) {
      biasingSearchViewModule().then(({ BiasingSearchView }) => {
        new BiasingSearchView();
        dispatcher.trigger('cookie:updateBiasingSearch', PRODUCT_SCRIPTS.PRODUCT_CODE, 1);
      });
    }

    msgRenovationViewModule().then(({MsgRenovationView}) => {
      new MsgRenovationView();
    });

    allMediasViewerGenericModule().then(({ allMediasViewerGeneric }) => {
      // build the configuration for allMediasViewerGeneric
      const sectionTrackingObject = (clickCodeAndgaAction, gaLabel) => {
        return {
          clickCode: clickCodeAndgaAction,
          gaCategory: PAGE_SCRIPTS.page_template,
          gaAction: clickCodeAndgaAction,
          gaLabel,
          event: 'clickEvent'
        };
      };
      const season = getSeason(PRODUCT_SCRIPTS.DATE_SELECTED);
      const allMediasViewerGenericConfig = {
        title: PRODUCT_SCRIPTS.PRODUCT_CODE === 'TH' ? PRODUCT_SCRIPTS.SITENAME + ' ' + PRODUCT_SCRIPTS.WORDING_PRODUCT.SUFFIX_H1_PRODUCT_HOME : PRODUCT_SCRIPTS.WORDING_PRODUCT.PREFIX_H1_PRODUCT_HOME + ' ' + PRODUCT_SCRIPTS.SITENAME,
        nav: true,
        tracking: {
          onShow: {
            popinName: 'mediaGallery',
            gaCategory: PAGE_SCRIPTS.page_template,
            gaAction: 'MediaGallery',
            gaLabel: 'all medias viewer',
            event: 'popinDisplay'
          },
          onClose: {
            clickCode: 'clickMediaGalleryClose',
            gaCategory: PAGE_SCRIPTS.page_template,
            gaAction: 'MediaGallery',
            gaLabel: 'Close',
            event: 'clickEvent'
          }
        },
        sections: [{
          name: 'photo',
          label: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_tab_photo,
          navIcon: ['camera'],
          tabs: true,
          retractNavOnScroll: true,
          waitAllSubsectionsToEnable: true,
          tracking: {
            onClickNav: sectionTrackingObject('clickMediaSection', PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_tab_photo),
            onClickTab: sectionTrackingObject('clickMediaFilter', PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_photo_photo)
          },
          ajaxOptions: {
            ajaxUrl: GLOBAL_SCRIPTS.URL_API_GO_MEDIA + 'photo',
            ajaxParams: {
              offers: PRODUCT_SCRIPTS.PRODUCT_CODE,
              season: PRODUCT_SCRIPTS.SEASON,
              formats: `${allMediasViewerGeneric.fancyboxPhotosResolution},${DEVICE.isDesktop ? '330x248,400x400,620x274' : '268x201,230x230,566x250'}`,
              selection: 'residence,galeriehbgt,galerieaqua,galerieactivite,galerierestau,galerieenvirons',
              language_code: GLOBAL_SCRIPTS.LANGUAGE_CODE,
              brand: GLOBAL_SCRIPTS.UNIVERS === 'sp' ? 'SP' : 'CP',
              order: 'order',
              no_mea: true,
              displayInfo: 1,
              univers: GLOBAL_SCRIPTS.UNIVERS,
              retry: 1,
              retryCheck: 'data.photos.galerieactivite',
              'retryParams[season]': 'SUMMER'
            }
          },
          empty: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_no_media_msg,
          subsections: [
            { name: 'essential', label: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_photo_essential, selection: 'residence' },
            { name: 'cottages', label: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_photo_cottages, selection: 'galeriehbgt' },
            { name: 'aqua', label: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_photo_aqua, selection: 'galerieaqua' },
            { name: 'activities', label: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_photo_activities, selection: 'galerieactivite' },
            { name: 'restauration', label: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_photo_restauration, selection: 'galerierestau' },
            { name: 'environs', label: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_photo_environ, selection: 'galerieenvirons' }
          ]
        }, {
          name: 'pixlee',
          label: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_tab_pixlee,
          navIcon: ['photos-clients'],
          tracking: {
            onClickNav: sectionTrackingObject('clickMediaSection', PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_tab_pixlee),
            onClickTab: sectionTrackingObject('clickMediaFilter', PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_photo_pixlee)
          },
          empty: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_no_media_msg,
          subsections: [{
            name: 'pixleeGrid',
            spinner: true,
            infinite_loading: true,
            ajaxOptions: {
              ajaxUrl: GLOBAL_SCRIPTS.URL_API_GO_MEDIA + 'pixlee?',
              ajaxParams: {
                token: GLOBAL_SCRIPTS.MEDIA_TOKEN,
                univers: 'cpe',
                market_code: GLOBAL_SCRIPTS.MARKET_CODE,
                language_code: GLOBAL_SCRIPTS.LANGUAGE_CODE,
                code: PRODUCT_SCRIPTS.PRODUCT_CODE,
                page: 1,
                per_page: DEVICE.isDesktop ? 60 : DEVICE.isTablet ? 30 : 15
              }
            },
            parseData: function (data) {
              if (data.data.data.length === 0) {
                return [];
              }

              return data.data.data.filter(image => {
                return !(image.original_size.width === null && image.photos.square_medium_url === '');
              }).map((image, index) => {
                let description = image.title !== null ? image.title : '';
                if (description.length > 160) {
                  description = description.substring(0, 160) + '...';
                }
                return {
                  src: image.photos.original_url,
                  grid_src: image.original_size.height === null ? image.photos.square_medium_url : image.photos.medium_url,
                  width: image.original_size.width === null ? 500 : image.original_size.width,
                  height: image.original_size.height === null ? 500 : image.original_size.height,
                  description,
                  network: 'instagram',
                  user_name: image.user_name,
                  href: image.platform_link,
                  index,
                  type: 'image',
                  next_page: data.data.next_page
                };
              });
            }
          }]
        }, {
          name: 'video',
          label: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_tab_video,
          navIcon: ['play'],
          resetOnRedisplay: true,
          tracking: {
            onClickNav: sectionTrackingObject('clickMediaSection', PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_tab_video),
            onClickTab: sectionTrackingObject('clickMediaFilter', PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_photo_video)
          },
          empty: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_no_media_msg,
          subsections: [{
            name: 'video',
            ajaxOptions: {
              ajaxUrl: GLOBAL_SCRIPTS.URL_API_GO_MEDIA + 'video',
              ajaxParams: {
                code: PRODUCT_SCRIPTS.PRODUCT_CODE,
                season: PRODUCT_SCRIPTS.SEASON,
                category: 'fp',
                market_code: GLOBAL_SCRIPTS.MARKET_CODE,
                language_code: GLOBAL_SCRIPTS.LANGUAGE_CODE,
                thumbs: 0,
                univers: GLOBAL_SCRIPTS.UNIVERS
              }
            },
            parseData: function (data) {
              if (data.data.length === 0) {
                return [];
              }

              return {
                type: 'iframe',
                className: 'video-iframe',
                src: data.data[0].value
              };
            },
            parseAllData: function (data) {
              if (!data.data || data.data.length === 0) {
                return [];
              }

              return data.data.map((video) => {
                const imgSrc = video.imgProperties && video.imgProperties.imgSrc
                  ? video.imgProperties.imgSrc
                  : getVideoThumbnail(video.value);

                const imgAlt = video.imgProperties && video.imgProperties.alt
                  ? video.imgProperties.alt
                  : '';

                return {
                  id: video.id,
                  type: 'iframe',
                  className: 'video-iframe',
                  src: video.value,
                  title: video.title,
                  imgSrc,
                  imgAlt
                };
              });
            }
          }]
        }]
      };

      if ($('[data-virtual-tour-src]').attr('data-virtual-tour-src')) {
        allMediasViewerGenericConfig.sections.push({
          name: 'virtual',
          label: PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_tab_virtual,
          navIcon: ['360'],
          tracking: {
            onClickNav: sectionTrackingObject('clickMediaSection', PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_tab_virtual),
            onClickTab: sectionTrackingObject('clickMediaFilter', PRODUCT_SCRIPTS.WORDING_PRODUCT.mediaViewer_label_photo_virtual)
          },
          subsections: [{
            name: 'virtual',
            parseData: function () {
              return {
                type: 'iframe',
                className: 'virtualTour-iframe',
                src: $('[data-virtual-tour-src]').attr('data-virtual-tour-src')
              };
            }
          }]
        });
      }

      if (isSunParks) {
        // remove Pixlee when isSunParks
        allMediasViewerGenericConfig.sections = allMediasViewerGenericConfig.sections.filter(i => i.name !== 'pixlee');
      }

      $(document).on('click', '.js-allMediasViewerGeneric--open', e => {
        const tab = $(e.currentTarget).data('anchor') || 'photo';
        const isMainVisual = tab === 'mainVisual';

        allMediasViewerGeneric.init(allMediasViewerGenericConfig, tab);

        Tracking.dataLayerPush({
          event: 'clickEvent',
          clickCode: isMainVisual ? 'clickAccommodationMainVisual' : 'clickAccommodationMedia',
          gaCategory: PAGE_SCRIPTS.page_template,
          gaAction: isMainVisual ? 'Click Main Visual' : 'Click Media Gallery',
          gaLabel: isMainVisual ? 'Main visual' : tab === 'photo' ? 'Photos & videos' : (tab === 'pixlee' ? 'Pixlee media' : (tab === 'virtual' ? 'Virtual tour' : '')),
          gaValue: 1
        });
      });

      const hashTab = allMediasViewerGeneric.getHashTab();
      hashTab && $(`.js-allMediasViewerGeneric--open[data-anchor="${hashTab}"]`).trigger('click');
    });

    // Initialise crossSell
    crossSellModule().then(({crossSell}) => {
      crossSell.initOrRefresh();
    });

    requestAnimationFrame(() => this.initBannerBadge());

    if (GLOBAL_SCRIPTS.IS_CGOS) {
      bookingFormModule().then(({BookingForm}) => {
        // Init booking form
        this.bookingForm = new BookingForm();
      });
    }
  },

  setProductHistoryCookie: function (searchDatas = {}) {
    const oHistoric = {};
    const currentCookie = cookieManagement.getCookie('productHistory');
    const currentCookieValue = currentCookie !== null ? JSON.parse(currentCookie) : false;

    const initialDatas = {
      startDate: PRODUCT_SCRIPTS.START_DATE,
      duration: PRODUCT_SCRIPTS.DURATION,
      capacity: PRODUCT_SCRIPTS.CAPACITY,
      hc: PRODUCT_SCRIPTS.SELECTED_HOUSING
    };

    const storedSearchParams = JSON.parse(cookieManagement.getCookie('storedSearchParams')) || {};
    const hasStoredSearchParams = !!(Object.keys(storedSearchParams).length);
    if ((hasStoredSearchParams && typeof storedSearchParams.isFlexPeriod !== 'undefined' && storedSearchParams.isFlexPeriod) || window.location.search.indexOf('option[flexholiday]') !== -1) {
      initialDatas.isFlexPeriod = true;
    }
    // Make trip duration equal to flexPeriod duration
    if (hasStoredSearchParams && initialDatas.isFlexPeriod) {
      initialDatas.duration = genericDate.diff(new Date(storedSearchParams.date[0].value), new Date(storedSearchParams.dateend[0].value));
    }

    oHistoric[PRODUCT_SCRIPTS.PRODUCT_CODE] = Object.keys(searchDatas).length ? searchDatas : initialDatas;

    if (currentCookieValue) {
      let nbItem = 0;

      $.each(currentCookieValue, (key, item) => {
        //on ajoute les produits déjà mémorisés
        if (key !== PRODUCT_SCRIPTS.PRODUCT_CODE && nbItem < 2) {
          nbItem++;
          oHistoric[key] = item;
        }
      });

      cookieManagement.eraseCookie('productHistory');
    }

    cookieManagement.createCookie('productHistory', JSON.stringify(oHistoric), 365);
  },

  /*
  * init nav fp tabs sticky
  *
  */
  initStickyNav: function () {
    if ($('.stickyNavFpContainer').length && PRODUCT_SCRIPTS.PRODUCT_TAB !== `tab_${GLOBAL_SCRIPTS.UNIVERS}_cottages`) {
      stickyElementModule().then(({ stickyElement }) => {
        const stickyElementData = {
          selector: 'body',
          fixedStart: () => {
            return $('.stickyNavFpContainer').offset().top;
          },
          fixedStartSelector: '.stickyNavFpContainer',
          classSticky: 'fixedNav',
          deviceList: 'desktop',
          nameSpace: 'stickyNavFp'
        };

        stickyElement.init(stickyElementData);
      });
    }
  },

  /*
  * init scroll after load
  *
  */
  initScrollAfterLoad: function () {
    const firstScrollPosition = window.scrollY;
    const stickyOffset = $('.stickyNavFpContainer').offset().top;
    const BtnSeeMore = !!$('.js-seeMoreCottage').length;

    if (PRODUCT_SCRIPTS.PRODUCT_TAB === `tab_${GLOBAL_SCRIPTS.UNIVERS}_cottages`) {
      setTimeout(function () {
        genericScrollTo.go(DEVICE.isMobile ? '.domainAccommodations-top' : '.searchbar', 1100);
      }, 500);
    } else if (stickyOffset < firstScrollPosition && BtnSeeMore) {
      genericScrollTo.go(firstScrollPosition + 1);

      $('.js-seeMoreCottage').each(function () {
        if ($(this).offset().top - ($(this).outerHeight(true) + $('.searchEngine-content').height()) < firstScrollPosition) {
          $(this).parent().hide();
          $(this).parent().next().removeClass('u-Hidden');
        }
      });
    }
  },

  /*
  * init scroll to top link in sticky nav
  *
  */
  initScrollToTop: function () {
    $('.js-scrollToTop').on('click', function (event) {
      event.preventDefault();
      genericScrollTo.go($('#mainContainer'), 500, 0);
    });
  },
  /*
  * init badge display on product page
  *
  */
  initBannerBadge: function () {
    const $bannerBadge = $('.js-headbandBadge');
    const visitedFP = JSON.parse(localStorage.getItem('visitedFP')) || [];
    const currentProductCode = PRODUCT_SCRIPTS.PRODUCT_CODE;

    if (visitedFP.includes(currentProductCode)) return;

    $bannerBadge
      .removeClass('u-Hidden--forced')
      .fadeIn(1000)
      .delay(3000)
      .fadeOut(1000);

    visitedFP.push(currentProductCode);
    localStorage.setItem('visitedFP', JSON.stringify(visitedFP));
  },
  /*
  * set params CSE referer and CGOS booking button
  *
  */
  setParamsCSE: function () {
    const urlParams = new URLSearchParams(window.location.search);
    const refererParam = urlParams.get('referer');
    const dateParam = urlParams.get('facet[DATE]');

    if (refererParam && refererParam !== '') {
      const dataCSE = {referer: refererParam};

      if (GLOBAL_SCRIPTS.IS_CGOS && dateParam) {
        dataCSE.date = dateParam;
        dataCSE.duration = urlParams.get('facet[DURATIONCP][0]');
      }

      cookieManagement.createCookie('dataCSE', JSON.stringify(dataCSE));
    }

    // Set data attributes for booking button CGOS
    if (GLOBAL_SCRIPTS.IS_CGOS) {
      const data_CSE = JSON.parse(cookieManagement.getCookie('dataCSE'));

      if (data_CSE) {
        const $btnBooking = $('.js-booking');

        $btnBooking.attr('data-code', PRODUCT_SCRIPTS.PRODUCT_CODE);

        if (data_CSE.date) {
          $btnBooking.attr('data-start', data_CSE.date);
        }

        if (data_CSE.duration) {
          $btnBooking.attr('data-duration', data_CSE.duration);
        }
      }
    }
  }
});

$(() => {
  new ProductDefault();

  // Update starting price on product tabs without search engine
  if (DEVICE.isDesktop && ![`tab_${GLOBAL_SCRIPTS.UNIVERS}_housing`, `tab_${GLOBAL_SCRIPTS.UNIVERS}_dayguest`].includes(PRODUCT_SCRIPTS.PRODUCT_TAB)) {
    startingPriceModule().then(({StartingPrice}) => {
      new StartingPrice();
    });
  }
});