/**
 * Get the thumbnail URL from a YouTube or Vimeo video.
 *
 * @param {string} videoUrl - The URL of the YouTube or Vimeo video.
 * @returns {string|null} - The thumbnail URL if found, otherwise null.
*/

export const getVideoThumbnail = (videoUrl) => {
  if (!videoUrl) return null;

  const youtubeRegex = /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const vimeoRegex = /(?:vimeo\.com\/(?:channels\/[\w]+\/|groups\/[\w]+\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?))/;

  const youtubeMatch = videoUrl.match(youtubeRegex);
  const vimeoMatch = videoUrl.match(vimeoRegex);

  if (youtubeMatch) {
    const videoId = youtubeMatch[1];
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  } else if (vimeoMatch) {
    const videoId = vimeoMatch[1];
    return `https://vumbnail.com/${videoId}.jpg`;
  }

  return null;
};
